import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export class RevealEffects {
    constructor(breakpointMedium) {
        this.breakpointMedium = breakpointMedium;
    }

    init() {
        this.setRevealClasses();
        let elemsBottom = gsap.utils.toArray(".reveal-item.from-bottom");
        let params = "top 100%";
        let dist = 50;
        let delay = 0;

        /* if (window.innerWidth < this.breakpointMedium) {
             params = "top 100%";
             dist = 50;
         } else {
             params = "top 90%";
             dist = 50;
         }*/
        elemsBottom.forEach(elem => {
            gsap.set(elem, { y: dist, alpha: 0 });
            gsap.fromTo(elem, { y: dist, alpha: 0 },
                {
                    scrollTrigger: {
                        trigger: elem,
                        //scrub: 1,
                        start: params,
                        //toggleActions: "play pause restart reset"
                    },
                    y: 0,
                    alpha: 1,
                    ease: "power4.out",
                    duration: 1.2
                });
        });

        /*if (window.innerWidth < this.breakpointMedium) {
            params = "top 100%";
        } else {
            params = "top 90%";
        }*/
        delay = 0; // 0.2
        let elemsFade = gsap.utils.toArray(".reveal-item.fade-in img");
        elemsFade.forEach(elem => {
            gsap.set(elem, { alpha: 0 });
            if (ScrollTrigger.isInViewport(elem, 0)) {
                gsap.fromTo(elem, { alpha: 0 },
                    {
                        scrollTrigger: {
                            trigger: elem,
                            //scrub: 1,
                            start: params,
                            //toggleActions: "play pause restart reset"
                        },
                        alpha: 1,
                        ease: "none",
                        duration: 0.6,
                        delay: delay
                    });
                delay += 0.1
            } else {
                gsap.fromTo(elem, { alpha: 0 },
                    {
                        scrollTrigger: {
                            trigger: elem,
                            //scrub: 1,
                            start: params,
                            //toggleActions: "play pause restart reset"
                        },
                        alpha: 1,
                        ease: "none",
                        duration: 0.6
                    });
            }
        });


        ////////// Leistungen ////////

        delay = 0;
        let elemsBottomDelay = gsap.utils.toArray(".reveal-item.from-bottom-delay");
        elemsBottomDelay.forEach(elem => {
            gsap.set(elem, { y: 50, alpha: 0 })
            if (ScrollTrigger.isInViewport(elem, 0)) {
                gsap.fromTo(elem, { y: 50, alpha: 0 },
                    {
                        scrollTrigger: {
                            trigger: elem,
                            start: params,
                            //toggleActions: "play pause restart reset"
                        },
                        y: 0,
                        alpha: 1,
                        ease: "power4.out",
                        duration: 1.6,
                        delay: delay
                    })
                delay += 0.1
            } else {
                gsap.fromTo(elem, { y: 50, alpha: 0 },
                    {
                        scrollTrigger: {
                            trigger: elem,
                            start: params,
                            //toggleActions: "play pause restart reset"
                        },
                        y: 0,
                        alpha: 1,
                        ease: "power4.out",
                        duration: 1.6,
                        delay: 0
                    })
                //delay += 0.1
            }
        })

         ////////// Testimonial ////////

         delay = 0;
         let elemsBottomDelay1 = gsap.utils.toArray(".reveal-item.from-bottom-delay1");
         elemsBottomDelay1.forEach(elem => {
             gsap.set(elem, { y: 50, alpha: 0 })
                 gsap.fromTo(elem, { y: 50, alpha: 0 },
                     {
                         scrollTrigger: {
                             trigger: elem,
                             start: params,
                             //toggleActions: "play pause restart reset"
                         },
                         y: 0,
                         alpha: 1,
                         ease: "power4.out",
                         duration: 1.6,
                         delay: delay
                     })
                 delay += 0.1
         })

        ////////// Leistungen ////////

        let elemsFadeDelay = gsap.utils.toArray(".reveal-item.fade-in-delay");
        delay = 0;
        elemsFadeDelay.forEach(elem => {
            gsap.set(elem, { backgroundColor: '#ffffff' })
            if (ScrollTrigger.isInViewport(elem, 0)) {
                gsap.fromTo(elem, { backgroundColor: '#ffffff' },
                    {
                        scrollTrigger: {
                            trigger: elem,
                            start: params,
                            //toggleActions: "play pause restart reset"
                        },
                        backgroundColor: "#ebea66",
                        ease: "none",
                        duration: 0.6,
                        delay: delay
                    })
                delay += 0.1
            } else {
                gsap.fromTo(elem, { backgroundColor: '#ffffff' },
                    {
                        scrollTrigger: {
                            trigger: elem,
                            start: params,
                            //toggleActions: "play pause restart reset"
                        },
                        backgroundColor: "#ebea66",
                        ease: "none",
                        duration: 0.6,
                        delay: delay
                    })
                delay += 0.1
            }
        })

        let elemsFadeDelay1 = gsap.utils.toArray(".reveal-item.fade-in-delay1");
        delay = 0.3;
        elemsFadeDelay1.forEach(elem => {
            gsap.set(elem, { opacity: 0 })
            if (ScrollTrigger.isInViewport(elem, 0)) {
                gsap.fromTo(elem, { opacity: 0 },
                    {
                        scrollTrigger: {
                            trigger: elem,
                            start: params,
                            //toggleActions: "play pause restart restart"
                        },
                        opacity: 1,
                        ease: "none",
                        duration: 0.6,
                        delay: delay
                    })
                delay += 0.1
            } else {
                gsap.fromTo(elem, { opacity: 0 },
                    {
                        scrollTrigger: {
                            trigger: elem,
                            start: params,
                            //toggleActions: "play pause restart reset"
                        },
                        opacity: 1,
                        ease: "none",
                        duration: 0.6,
                        delay: delay
                    })
                delay += 0.1
            }
        })
    }

    setRevealClasses() {
        let elemsTxt = gsap.utils.toArray('.entry-content .headline-text');
        elemsTxt.forEach(elem => {
            elem.classList.add('reveal-item', 'from-bottom');
        });

        let elemsTxt1 = gsap.utils.toArray('.entry-content .col-md-6 .single-headline-text');
        elemsTxt1.forEach(elem => {
            elem.classList.add('reveal-item', 'from-bottom');
        });
        let elemsTxt2 = gsap.utils.toArray('.entry-content .col-md-5 .single-headline-text');
        elemsTxt2.forEach(elem => {
            elem.classList.add('reveal-item', 'from-bottom');
        });


        let elemsImg = gsap.utils.toArray('.home .entry-content figure');
        elemsImg.forEach(elem => {
            elem.classList.add('reveal-item', 'fade-in');
        });

        let elemsImg1 = gsap.utils.toArray('.entry-content .about-container figure');
        elemsImg1.forEach(elem => {
            elem.classList.add('reveal-item', 'fade-in');
        });

        let elemsImg2 = gsap.utils.toArray('.single .entry-content figure');
        elemsImg2.forEach(elem => {
            elem.classList.add('reveal-item', 'fade-in');
        });

        let elemsBottomDelay = gsap.utils.toArray('.services');
        elemsBottomDelay.forEach(elem => {
            elem.classList.add('reveal-item', 'from-bottom-delay');
        });

        let elemsBottomDelay1 = gsap.utils.toArray('.reference');
        elemsBottomDelay1.forEach(elem => {
            elem.classList.add('reveal-item', 'from-bottom-delay');
        });
        let elemsFadeIn = gsap.utils.toArray('.entry-content .reference figure');
        elemsFadeIn.forEach(elem => {
            elem.classList.add('reveal-item', 'fade-in');
        });

        let elemsBottomDelay2 = gsap.utils.toArray('.testimonial');
        elemsBottomDelay2.forEach(elem => {
            elem.classList.add('reveal-item', 'from-bottom-delay1');
        });

        let elemsFadeDelay = gsap.utils.toArray('.services-desc');
        elemsFadeDelay.forEach(elem => {
            elem.classList.add('reveal-item', 'fade-in-delay');
        });

        let elemsFadeDelay1 = gsap.utils.toArray('.services-desc-content');
        elemsFadeDelay1.forEach(elem => {
            elem.classList.add('reveal-item', 'fade-in-delay1');
        });
    }
}
import { BaseFunctions } from "/shoeelk_src/js/basefunctions.js";
import { BurgerNavi } from "/shoeelk_src/js/burgernavi.js";
import { StartAnimation } from "/shoeelk_src/js/startanimation.js";
import { RevealEffects } from "/shoeelk_src/js/revealeffects.js";

const breakpointMedium = 1024;
const breakpointSmall = 600;

document.addEventListener("DOMContentLoaded", function (event) {

    const basefunctions = new BaseFunctions(breakpointMedium, breakpointSmall);
    basefunctions.init();

    const burgernavi = new BurgerNavi();
    burgernavi.init();

    const revealeffects = new RevealEffects();
    revealeffects.init();

    if (document.querySelectorAll('.home-animation-wrapper').length > 0) {
        const startanimation = new StartAnimation();
        startanimation.init();
    }

    basefunctions.fadeIn();
});
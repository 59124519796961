import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
gsap.registerPlugin(ScrollToPlugin);

export class BaseFunctions {
    constructor(breakpointMedium, breakpointSmall) {
        this.breakpointMedium = breakpointMedium;
        this.breakpointSmall = breakpointSmall;
        this.lastScrollTop = 0;
    }
    init() {
        this.setMinHeader();
        if (document.querySelectorAll('.arrow-down').length > 0) {
            this.setArrowDown();
        }
    }
    setMinHeader() {
        window.addEventListener('scroll', this.shrinkLogo.bind(this));
    }
    shrinkLogo(evt) {
        const logo = document.querySelector('.wp-block-site-logo');
        const burger = document.querySelector('.burger-icon-wrapper');
        const header = document.querySelector('header.site-header .header-bg');
        let dir = this.getSCrollDirection();
        if (window.scrollY > 100) { // 160
            logo.classList.add('small');
        } else {
            logo.classList.remove('small');
        }
        /*if (window.innerWidth < this.breakpointMedium && window.scrollY > 160) {
            if (dir == 'down') {
                logo.classList.add('moveup');
            } else {
                logo.classList.remove('moveup');
            }
        } else {
            logo.classList.remove('moveup');
        }*/
        if (window.innerWidth < this.breakpointSmall && window.scrollY > 160) {
            if (dir == 'down') {
                burger.classList.add('moveup');
            } else {
                burger.classList.remove('moveup');
            }
        } else {
            burger.classList.remove('moveup');
        }
        if (window.scrollY > 100) {
            if (dir == 'down') {
                logo.classList.add('moveup');
            } else {
                logo.classList.remove('moveup');
            }
        } else {
            logo.classList.remove('moveup');
        }
        if (window.scrollY > 100) {
            if (dir == 'down') {
                header.classList.add('moveup');
            } else {
                header.classList.remove('moveup');
            }
        } else {
            header.classList.remove('moveup');
        }
        if (document.querySelectorAll('body.home').length > 0) {
            if (window.scrollY > window.innerHeight) {
                header.classList.add('has-white-background-color');
            } else {
                header.classList.remove('has-white-background-color');
            }
        }
    }
    getSCrollDirection() {
        const self = this;
        let scrollPos = window.scrollY;
        let scrollDirection = '';
        scrollPos = window.scrollY;
        if (scrollPos > this.lastScrollTop) {
            scrollDirection = 'down';
        } else {
            scrollDirection = 'up';
        }
        this.lastScrollTop = scrollPos <= 0 ? 0 : scrollPos;
        return scrollDirection;
    }
    fadeIn() {
        document.querySelector('.entry-content').classList.add('show');
    }
    setArrowDown() {
        if (document.querySelectorAll('.arrow-down').length > 0) {
            let dist = 60;
            if (window.innerWidth < this.breakpointSmall) {
                dist = 0;
            }
            document.querySelector('.arrow-down').addEventListener('click', function () {
                let p = document.querySelector('.home-animation-wrapper').getBoundingClientRect().height - dist;
                gsap.to(window, { duration: 1.0, scrollTo: p, ease: "power3.out" });
                document.querySelector('.arrow-down').classList.add('hidden');
            });
            window.addEventListener('scroll', function () {
                document.querySelector('.arrow-down').classList.add('hidden');
            })
        }
    }
}
import { gsap } from "gsap";

export class StartAnimation {
    constructor() {
        this.cgreen = document.querySelector('.circle-green');
        this.cblue = document.querySelector('.circle-blue');
        this.logo = document.querySelector('.wp-block-site-logo img');
        this.quote = document.querySelector('.home-header');
        this.randomX = 0;
        this.randomY = 0;
    }

    init() {
        const self = this;
        if (window.innerWidth > window.innerHeight) {
            gsap.to(this.cgreen, { width: window.innerHeight * 0.8, height: window.innerHeight * 0.8, x: -window.innerHeight * 0.2, y: window.innerHeight * 0.15, duration: 4.2, ease: 'power2.out', onComplete: this.startBubbleGreen, onCompleteParams: [self.cgreen, self, 'green'] });
            gsap.to(this.cblue, { width: window.innerHeight * 0.85, height: window.innerHeight * 0.85, x: 0, y: window.innerHeight * 0.05, rotation: 0, duration: 4.2, ease: 'power2.out', delay: 0.5, onComplete: this.startBubbleBlue, onCompleteParams: [self.cblue, self, 'blue'] });
        } else {
            gsap.to(this.cgreen, { width: window.innerWidth * 0.8, height: window.innerWidth * 0.8, x: -window.innerWidth * 0.2, y: window.innerHeight * 0.35, duration: 4.2, ease: 'power2.out', onComplete: this.startBubbleGreen, onCompleteParams: [self.cgreen, self, 'green'] });
            gsap.to(this.cblue, { width: window.innerWidth * 0.85, height: window.innerWidth * 0.85, x: 0, y: window.innerHeight * 0.25, rotation: 0, duration: 4.2, ease: 'power2.out', delay: 0.5, onComplete: this.startBubbleBlue, onCompleteParams: [self.cblue, self, 'blue'] });
        }
        gsap.to(this.logo, { y: 0, duration: 1.2, ease: 'power2.out', delay: 1.8 });
        gsap.to(this.quote, { opacity: 1, duration: 1.4, ease: 'none', delay: 3.2 });

        gsap.to('.entry-content + .socialmedia-icons', { opacity: 1, duration: 0.8, ease: 'none', delay: 4 });
        gsap.to('.burger-icon-wrapper', { opacity: 1, duration: 0.8, ease: 'none', delay: 4 });
        gsap.to('.arrow-down', { opacity: 1, duration: 0.6, ease: 'none', delay: 4 });

        window.addEventListener('resize', this.resize.bind(this));
    }
    startBubbleGreen(elem, self, color) {
        if (elem.elemX == undefined) {
            elem.elemX = gsap.getProperty(elem, "x");
            elem.elemY = gsap.getProperty(elem, "y");
        }
        var randomX = elem.elemX - 10 + Math.round(Math.random() * 20);
        var randomY = elem.elemY - 10 + Math.round(Math.random() * 20);
        let rotation = Math.round(-10 + Math.random() * 20);
        let duration = (elem.elemX - randomX) / 20;
        if (duration < 0) duration = duration * -1;
        if (duration < 0.4) duration = 0.4;
        gsap.to(elem, { x: randomX, y: randomY, duration: duration, rotation: rotation, ease: 'none', onComplete: self.startBubbleGreen, onCompleteParams: [elem, self, color] });
    }
    startBubbleBlue(elem, self, color) {
        if (elem.elemX == undefined) {
            elem.elemX = gsap.getProperty(elem, "x");
            elem.elemY = gsap.getProperty(elem, "y");
        }
        var randomX = elem.elemX - 10 + Math.round(Math.random() * 20);
        var randomY = elem.elemY - 10 + Math.round(Math.random() * 20);
        let rotation = Math.round(-10 + Math.random() * 30);
        let duration = (elem.elemX - randomX) / 20;
        if (duration < 0) duration = duration * -1;
        if (duration < 0.4) duration = 0.4;
        gsap.to(elem, { x: randomX, y: randomY, duration: duration, rotation: rotation, ease: 'none', onComplete: self.startBubbleBlue, onCompleteParams: [elem, self, color] });
    }
    resize() {
        this.cgreen.elemX = undefined;
        this.cgreen.elemY = undefined;
        this.cblue.elemX = undefined;
        this.cblue.elemY = undefined;
        if (window.innerWidth > window.innerHeight) {
            gsap.set(this.cgreen, { width: window.innerHeight * 0.8, height: window.innerHeight * 0.8, x: -window.innerHeight * 0.2, y: window.innerHeight * 0.15});
            gsap.set(this.cblue, { width: window.innerHeight * 0.85, height: window.innerHeight * 0.85, x: 0, y: window.innerHeight * 0.05, rotation: 0 });
            this.startBubbleGreen(this.cgreen, this, 'green');
            this.startBubbleBlue(this.cblue, this, 'blue');
        } else {
            gsap.to(this.cgreen, { width: window.innerWidth * 0.8, height: window.innerWidth * 0.8, x: -window.innerWidth * 0.2, y: window.innerHeight * 0.35 });
            gsap.to(this.cblue, { width: window.innerWidth * 0.85, height: window.innerWidth * 0.85, x: 0, y: window.innerHeight * 0.25, rotation: 0 });
            this.startBubbleGreen(this.cgreen, this, 'green');
            this.startBubbleBlue(this.cblue, this, 'blue');
        }
    }
}

